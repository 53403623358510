import { ApplicationRef, Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';
import { merge } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { I18nService } from '@app/i18n';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScrollService } from './calculators/services/scroller-service/scroll.service';

const log = new Logger('App');

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private _scrollService = inject(ScrollService);
  private activatedRoute = inject(ActivatedRoute);
  private titleService = inject(Title);
  private translateService = inject(TranslocoService);
  private i18nService = inject(I18nService);
  private breakpoint = inject(BreakpointObserver);
  private metaTagService = inject(Meta);
  private appRef = inject(ApplicationRef);
  private updates = inject(SwUpdate);
  private snackbar = inject(MatSnackBar);
  @ViewChild('sidenav', { static: false }) sidenav!: MatSidenav;

  language: string = '';

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }
    this.initializePageTranslations();
    this.invokeMetaTags();
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }

  /**
   * @description Initializing i18n translations
   */
  initializePageTranslations() {
    log.debug('init');
    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
    this.onRouteChangePageTitle();
  }

  /**
   * @description Change page title on navigation or language change, based on route data
   */
  onRouteChangePageTitle() {
    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
    merge(this.translateService.langChanges$, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this),
      )
      .subscribe((event) => {
        const title = event['title'];
        if (title) {
          const translationPointer = title.split('.');
          const translationString = translationPointer.splice(1).join().replaceAll(',', '.');
          this.translateService
            .selectTranslate(translationString, {}, translationPointer[0])
            .pipe(take(1), untilDestroyed(this))
            .subscribe((value) => this.titleService.setTitle(value));
          this.language = this.i18nService.language.endsWith('-CH')
            ? this.i18nService.language.split('-')[0]
            : this.i18nService.language;
        }
      });
    this.breakpoint
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(
        filter(({ matches }) => !matches),
        untilDestroyed(this),
      )
      .subscribe(() => this.sidenav?.close());
  }

  onContentScroll(event: Event) {
    const target = event.target as HTMLElement;
    this._scrollService.setScrollY(target.scrollTop);
  }

  invokeMetaTags() {
    this.metaTagService.addTags([
      {
        name: 'description',
        content:
          'Die innovative Lösung zur Baukostenschätzung und Baukostenberechnung: Neubau-, Betriebs- und Umbaukosten. Jetzt 7 Tage gratis testen!',
      },
      { name: 'Keywords', content: 'Baukostenschätzung & Baukostenberechnung' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      //google
      { name: 'google', content: 'notranslate' },
      // Indexing / Spiders
      // 'googlebot': 'all',
      // 'bingbot': 'all',
      // 'robots': 'all',
      // OpenGraph
      { property: 'og:site_name', content: 'website' },
      { property: 'og:image', content: 'https://www.keevalue.ch/assets/images/background/og/opengraph_bg_1000.jpg' },
      {
        property: 'og:image:width',
        content: 'Die schweizerische & innovative Lösung zur Ermittlung von Neubau-, Betriebs- und Umbaukosten.',
      },
      {
        property: 'og:image:height',
        content: 'Die schweizerische & innovative Lösung zur Ermittlung von Neubau-, Betriebs- und Umbaukosten.',
      },
      // Twitter
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@kennwerte' },
      { name: 'twitter:image', content: 'https://www.keevalue.ch/assets/images/background/og/opengraph_bg_1000.jpg' },
    ]);
  }
}
