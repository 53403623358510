import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { map, Observable, of, skipWhile } from 'rxjs';
import { Logger } from '@shared';
import { AppRoute } from 'src/generated/extended';
import { BillingService } from '@app/core/services/billing/billing/billing.service';
import { LicenseStateDTO, WithdrawalProductType } from '@generated/generatedEntities';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError } from 'rxjs/operators';

const log = new Logger('HasLicenseGuard');

const restrictedRoutes = [
  'process-form',
  'operational-form',
  'lcc-form',
  'lca-form',
  'effort-planner-form',
  'gvbs',
  'damage-form',
  'insurance-form',
  'agv',
  'planning-foundation-form',
  'realestates',
  'realestates-reference',
];

const INSUFFICIENT_ROUTE = 'insufficient-licenses';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class HasLicenseGuard {
  private router = inject(Router);
  private _licenseState = inject(BillingService);

  constructor() {
    const parent = inject(HasLicenseGuard, { optional: true, skipSelf: true });

    // log.error('init');
    if (parent) {
      throw Error(
        `[GuardedSingletonService]: trying to create multiple instances,
        but this service should be a singleton.`,
      );
    }
  }

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const routerData = route as AppRoute;
    return this.hasLicenseGuard(routerData);
  }

  private hasLicenseGuard(route: AppRoute) {
    return this._licenseState.licenseState().pipe(
      untilDestroyed(this),
      skipWhile((state: any) => state == null),
      map((state: LicenseStateDTO) => {
        if (state.licenseState !== 'INVALID') return true;
        const router = route as ActivatedRouteSnapshot;
        const path = router.routeConfig?.path;
        const getModules = router.data['module'] ? router.data['module'] : router.data['modules'];
        const module = this.setModules(getModules);
        restrictedRoutes.some((restrictedRoute) => {
          return this.onNavigate(path, restrictedRoute, module);
        });
        return false;
      }),
      catchError(() => of(false)),
    );
  }

  private setModules(module: WithdrawalProductType | WithdrawalProductType[]) {
    return Array.isArray(module) ? module : [module];
  }

  private onNavigate(
    path: string | undefined,
    route: string,
    module: WithdrawalProductType | WithdrawalProductType[],
  ): Boolean {
    if (path?.includes(route) || route.includes('realestates')) {
      this.router.navigate([INSUFFICIENT_ROUTE], { replaceUrl: true, state: { data: { module: module } } });
    }
    return false;
  }
}
